<script>
import { mapState } from 'vuex'
import Config from './Config'
import Gen from '@libs/Gen.js'
import $ from 'jquery'
import _ from 'lodash'

export default {
  data(){
    return {
      filter: {},
      row: {},
      input:{},
      mrValidation: {},
      data: {data: false},
      dataLength: 0,
      mrStatus: [],
      duration:35000,
      pattern:{
        name:/^[a-zA-Z '.,]+$/,
        username:/^[a-zA-Z0-9_.]+$/,
        phone:/^(?=[1-9])[0-9]+$/,
      },
    }
  },
  computed:{
    ...mapState({
      production: state => state.production,
      apiUrl: state => state.apiUrl,
      baseUrl: state => state.baseUrl,
      webTitle: state => state.webTitle,
      userToken: state => state.userToken,
      user: state => state.user,
    }),
    loadingOverlay:{
      get(){ return this.$root.var.loadingOverlay },
      set(v){ return this.$root.var.loadingOverlay = v },
    },
    Config(){ return Config },

    pageSlug(){ return this.$route.params.pageSlug },
    pageId(){ return this.$route.params.pageId },
    isAdd(){ return this.pageSlug == "add" },
    isList(){ return !this.pageSlug },
    isSorting(){return this.$route.query.sorting&&this.isList},
    isDelete(){return this.$route.query.delete&&this.isList},

    dataList(){return this.data.data },

    pageTitle(){return this.$route.meta?.pageTitle||""},
    pageTitleForm(){return (this.pageSlug == 'add' ? 'Add' : 'Edit')+' '+this.pageTitle},
    modulePage(){return this.$route.meta.menu},

    pageNo: {
      // getter
      get:function() {
        return this.filter.page||this.$route.query.page||1
      },
      // setter
      set:function(newValue) {
        this.$set(this.filter, "page", newValue);
        this.$router.push({
          name:this.modulePage,
          query:_.clone(this.filter)
        })
      }
    },

    perPage:{
      // getter
      get:function() {
        return this.filter.shown||this.$route.query.shown||Config.dataShown
      },
      // setter
      set:function(newValue) {
        this.$set(this.filter, "shown", newValue);
        this.$router.push({
          name:this.modulePage,
          query:_.clone(this.filter)
        })
      }
    },

    apiParams(){ return _.assign(_.clone(this.filter), _.clone(this.apiQuery||{})) },
    apiQuery(){return this.$route.query||{}},

    notFound(){
      if (this.data.data === false) return false
      if (this.data.data.length > 0) return false
      if (this.$route.query.search) {
        return "Search with '" + this.$route.query.search + "' keyword not found."
      }
      return this.pageTitle + " not found."
    },
    filterNotEmpty(){
      return $.grep(Object.keys(this.filter)||[], (attr)=>{
        return this.filter[attr] && attr!='page'
      }).length >= 1
    },
  },
  mounted() {
    this.filter = _.clone(this.$route?.query)
  },
  methods:{
    isType(data){
      return $.type(data)
    },
    apiGet(params = {}){
      if(this.pageSlug) this.loadingOverlay = true
      this.data.data = false
      Gen.apiRest(
        "/get/"+this.modulePage+
        (this.pageSlug?"/"+this.pageSlug:"")+
        (this.pageId?"/"+this.pageId:""), 
        {
          params: Object.assign({}, this.apiParams, params.query||{})
        }
      ).then(res=>{
        this.loadingOverlay = false
        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })
        
        if(!this.isList){
          this.setFocusForm()
        }
        
        if(params.callback) params.callback()
      }).catch(()=>{
        this.loadingOverlay = false
      })
    },
    setFocusForm(){
      if(this.$refs.VForm.$el){
        let el = $(this.$refs.VForm.$el);
        if(el.find(':input:not([type=hidden])').length){
          el.find(':input:not([type=hidden]):first').focus()
        }else if(el.find('textarea').length){
          el.find('textarea:first').focus()
        }
      }
    },
    setTitle(){
      $("title").html(this.pageTitle+' - '+this.webTitle)
    },
    doFilter() {
      if (this.exportType && this.filter) {
        let f = Object.values(this.filter).filter((attr) => attr)
        if (f.length >= 1) {
          this.exportType = 'filtered'
        } else {
          this.exportType = 'all'
        }
      }
      this.$router
        .push({
          name: this.$route.name,
          query: Object.assign({}, _.clone(this.filter), { page: 1 }),
        })
        .catch(() => {})

      let url = window.location;
      
      $('ul#sidebarnav a').filter(function () {
          return this.href == (url.origin + url.pathname);
      }).addClass('active').parent().addClass('active');
    },
    doReset(){
      this.filter={}
      this.$router.push({name:this.$route.name,query:{page:1}});
    },
    doChangeStatus(k,v, modulePage = this.modulePage, statusKey = this.statusKey, idKey = this.idKey){
      let id = v[idKey]
      let changeStatus = v[statusKey] == "Y" ? "N" : "Y"
      global.Swal.fire({
        title: `Change status to ${changeStatus=='Y' ? 'Active' : 'Inactive'}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: changeStatus=="Y"?'#0145DC':'#f7ab38',
        cancelButtonColor: '#3085d6',
        confirmButtonText:  `Yes, change to ${changeStatus=='Y'? 'Active' : 'Inactive'}!`,
        cancelButtonText:  'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
      }).then((result) => {
        if (result.value) {
          this.loadingOverlay=true

          Gen.apiRest(
            "/do/"+modulePage,
            {data:{type:'status',id:id,status:changeStatus}}, 
            "POST"
          )
            .then(()=>{
              this.loadingOverlay = false 
              v[statusKey] = changeStatus

              global.Swal.fire({
                title: `Status telah berubah ke ${changeStatus=='Y'? 'Active' : 'Inactive'}.`,
                icon: 'success',
                confirmButtonColor: '#0145DC',
              })

              this.apiGet()
            })
            .catch((err)=>{ 
              this.loadingOverlay = false 
              if(err){
                  err.statusType = err.status;
                  err.status = "error";
                  err.message = err.response.data.message;
                  err.messageError = err.message
              }
              this.doSetAlertForm(err);
            })
        }
      })
    },
    doDelete(k,v, modulePage = this.modulePage, idKey = this.idKey){
      let id = v[idKey]
      global.Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to recover this data.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete',
        cancelButtonText:  'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
      }).then((result) => {
        if (result.value) {
          this.loadingOverlay = true
          Gen.apiRest("/do/"+modulePage,{data:{type:'delete',id:id}}, "POST")
            .then(()=>{
              this.apiGet()
              global.Swal.fire({
                title: 'Data has been successfully deleted.',
                icon: 'success',
                confirmButtonColor: '#0145DC',
              })
            })
            .catch((err)=>{ 
              this.loadingOverlay = false 
              if(err){
                  err.statusType = err.status;
                  err.status = "error";
                  err.message = err.response.data.message;
                  err.messageError = err.message
              }
              this.doSetAlertForm(err);
            })
        }
      })
    },
    reparse(js){
      return $.parseJSON(JSON.stringify(js))
    },
    doPaginate(page = 1){
      this.$set(this.filter, "page", page);
      this.$router.push({name:this.$route.name,query:_.clone(this.filter)})
    },
    doSubmit(action, input, callback=false, method="POST", IDFORM='VForm', usingKey = false){
      // this.$refs[IDFORM].validate().then(success => {
      let refForm = this.$refs[IDFORM];
      
      if(usingKey) {
        refForm = this.$refs[IDFORM][0];
      }

      refForm.validate().then(success => {
        if (!success) { return; }
        
        if(this.loadingOverlay) return
        this.loadingOverlay = true 

        var data = $.parseJSON(JSON.stringify(input));
        var btnSubmit = $(".btn-loading");
        var prevHtml = btnSubmit.html();

        if($(".infoHTML").length){
          $(".infoHTML").removeClass('alert alert-danger alert-warning');
          $(".infoHTML").html('');
        }

        btnSubmit.html();
        Gen.apiRest(action, {data: $.extend({params:this.params}, data)}, method).then((res)=>{
            this.loadingOverlay = false
            let resp = res.data

            btnSubmit.html(prevHtml);

            resp.statusType = 200;
            this.doSetAlertForm(resp);
            
            if(callback){
              callback('success', resp)
            }

            setTimeout(()=>{ this.message="" },15000);
        }).catch((err)=>{
            this.loadingOverlay = false
            btnSubmit.html(prevHtml);
            
            if(err){
                err.statusType = err.status;
                err.status = "error";
                err.message = err.response?.data?.message;
                err.messageError = err.message
            }

            // this.doSetAlertForm(err);
            if(this.failReset)
                this.$nextTick(() => {
                    this.$refs.form.reset();
                    this.message=""
                });
                
            if(callback){
              callback('error', err)
            }
        })
      });
    },
    updateHero(IDForm='VForm', formData = this.inputHeroImg){
      return this.doSubmit(
        '/do/' + this.modulePage,
        _.assign(
          { type: 'updateHeroContent', slug: this.$route.path },
          {},
          _.clone(formData)
        ),
        (type) => {
          if (type === 'success') {
            this.apiGet()
            this.editFormId = ''
            setTimeout(() => {
              this.$bvModal.hide('heroImageModal')
            }, 2000)
          }
        },
        'POST',
        IDForm
      )
    },
    doSubmitCRUD(IDForm='VForm', modulePage = this.modulePage, id = (this.pageId||this.pageSlug), formpage = this.row, isAdd = this.isAdd, modalId = null){

      return this.doSubmit(
          "/do/"+modulePage, 
          _.assign({type:isAdd?'add':'update'}, {id: id}, _.clone(formpage)),
          (type, resp)=>{
            if(type=='success'){
              this.$router.push({name:this.$route.name})
              this.apiGet()
              if(modalId) {
                this.$bvModal.hide(modalId)
              }
            }else{
              if(resp.response.status==422) {
                var msg = ""
                $.each(Object.keys(resp.response.data.message),(i,v)=>{
                  msg = msg+"<li>"+_.values(resp.response.data.message[v])+"</li>"; // remove prefix
                })
                msg = "<ul style=\"text-align:left;\">"+msg+"</ul>"
                return global.Swal.fire("Validation Exception", msg)
              }else if(resp.response.status==400){
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: '#0145DC',
                })
              }else{
                return global.Swal.fire("Oops. Something went wrong.", "")
              }
            }
          },
          'POST', IDForm
      )
    },
    eType(e){return $.type(e)},
    doImport(){
      return this.doSubmit( "/do/"+this.modulePage, _.assign({type:'import-excel'}, {id:0}, _.clone(this.input)), (type, resp)=>{
          if(type=='success'){
            this.apiGet()
            this.$router.push({name:this.$route.name})
          }else{
            if(resp.response.status==400){
              return global.Swal.fire({
                title: resp.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#0145DC',
              })
            }
          }
        }
      )
    },
    doSubmitWithoutValidate(action, input, callback=false, method="POST"){
      if(this.loadingOverlay) return
      this.loadingOverlay = true 

      var data = $.parseJSON(JSON.stringify(input));
      var btnSubmit = $(".btn-loading");
      var prevHtml = btnSubmit.html();

      if($(".infoHTML").length){
        $(".infoHTML").removeClass('alert alert-danger alert-warning');
        $(".infoHTML").html('');
      }

      btnSubmit.html();
      Gen.apiRest(action, {data: $.extend({params:this.params}, data)}, method).then((res)=>{
          this.loadingOverlay = false
          let resp = res.data

          btnSubmit.html(prevHtml);

          resp.statusType = 200;
          this.doSetAlertForm(resp);
          
          if(callback){
            callback('success', resp)
          }

          setTimeout(()=>{ this.message="" },15000);
      }).catch((err)=>{
          this.loadingOverlay = false
          btnSubmit.html(prevHtml);
          
          if(err){
              err.statusType = err.status;
              err.status = "error";
              err.message = err.response?.data?.message;
              err.messageError = err.message
          }

          this.doSetAlertForm(err);
          if(this.failReset)
              this.$nextTick(() => {
                  this.$refs.form.reset();
                  this.message=""
              });
              
          if(callback){
            callback('error', err)
          }
      })
    },
    doSetAlertForm(resp) {
      /* eslint-disable no-unreachable */
      if (!resp.message) return

      let panel;
      let closepanel;
      if (
        resp.vid === null || 
        resp.vid === "" || 
        resp.vid === -1 || 
        Number.isNaN(resp.vid) || 
        resp.vid === undefined
      ) {
        panel = $('.infoHTML');
        closepanel = 'infoHTML';
      } else {
        panel = $('.infoHTML' + resp.vid ? '.' + resp.vid : '');
        closepanel = `infoHTML ${resp.vid}`; 
      }
      
      if (resp.alert === 'normal' || ((resp.response || {}).data || {}).alert === 'normal') {
        if (['success', 'warning'].indexOf(resp.status) > -1) {
          panel.attr('class', 'infoHTML alert alert-' + resp.status)
        } else {
          let data;

          if (resp.message) {
            data = resp;
          } else if ((resp.response || {}).data) {
            data = resp.response.data
          }

          panel.attr('class', 'infoHTML alert alert-danger')

          panel.html("<div class='alert-body'>" + data.message + '</div>')
        }
        panel.html("<div class='alert-body'>" + resp.message + '</div>')
        if (resp.modal) {
          $(resp.modal).animate({
            scrollTop: 0
          });
        }

        if (resp.response?.data?.modal) {
          $(resp.response?.data?.modal).animate({
            scrollTop: 0
          });
        }
        
        setTimeout(() => {
          panel.html('')
          panel.attr('class', closepanel)
        }, 3000)
      } else {
        if ($.inArray(resp.status, ['success', 'warning']) != -1) {
          return global.Swal.fire({
            title: 'Information',
            text: resp.message,
            icon: resp.status,
            confirmButtonText: 'Ok',
            confirmButtonColor: '#0145DC',
          })
        } else {
          return global.Swal.fire({
            title: (resp.response || {}).status === 500 ? 'Server Error' : resp.message,
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#0145DC',
          })
        }
      }
    },
    errorMessage(err){
      this.loadingOverlay = false
      let res = err.response
      if(res.status==422) return global.Swal.fire("Oops. Something went wrong.", _.values(res.data)[0][0])
      global.Swal.fire("Oops. Something went wrong.", res.data.message, "warning")
    },
    doLogout:function(){
      this.$store.commit("logoutUserToken")
      if(!this.userToken){
        this.$router.push({name:"Login"})
      }
    },
    uploader(filename, square = false) {
      if (!filename) {
        if (square) {
          return this.apiUrl + '/img/noimage-square.png'
        }
        return this.apiUrl + '/img/noimage.png'
      }
      return this.apiUrl + '/uploader/' + filename
    },
    configValue(data, id){
      let dt = $.grep((Config[data]||[]), (attr)=>{ return attr.id==id })
      return dt.length?dt[0].text:""
    },
    moduleRole(role) {
      if (this.user.levelId == 0) return true
      return (this.user.moduleRole || []).indexOf(role) > -1
    },
    menuRole(role) {
      if (this.user.levelId == 0) return true
      return (this.user.menuRole || []).indexOf(role) > -1
    },
    moduleRedirect(add = true) {
      const { pageSlug } = this.$route.params;
      if (pageSlug) {
        if (pageSlug == "add" && add ? !this.moduleRole("Add") : false) {
          this.$router.push({ name: this.$route.name });
        } else if (pageSlug !== "add" && !this.moduleRole("Edit")) {
          this.$router.push({ name: this.$route.name });
        }
      }
    },

    toggleSidebar(){
			document.querySelector("body").classList.toggle('sidebar-mobile-main');
			if (document.querySelector(".sidebar-main").classList.contains('sidebar-fullscreen')) {
				document.querySelector(".sidebar-main").classList.remove('sidebar-fullscreen')
			}
		},
		fullscreenSidebar(){
			document.querySelector(".sidebar-main").classList.toggle('sidebar-fullscreen');
		},
    toggleEditForm(id) {
      if (this.editFormId == id) {
        this.editFormId = ''
        this.apiGet()
        return null
      }
      this.editFormId = id
    },
    sanitize(input) {
      return input.replace(/<\/?[^>]+(>|$)/g, "").replace('&nbsp;', ' ');
    },
    formatDate(date) {
      if (!date) {
        return;
      }

      if (date instanceof Date) {
        return date.toLocaleDateString('id-ID', {
          weekday: 'long',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })
      }

      if ((date.toString() || '').indexOf('/') !== -1) {
        // unslashed date
        let usd = date.split('/');
        
        let formatted = new Date(usd[2], parseInt(usd[0]) - 1, usd[1]);

        return formatted.toLocaleDateString('id-ID', {
          weekday: 'long',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })
      }

      const isParsed = (date || '').indexOf('T') !== -1;

      // kalo formatnya 2022-01-01 15:00:00 di ubah jadi 2022-01-01T15:00:00
      const d = !isParsed ? date.replace(/ /g, 'T') : date;

      return new Date(d).toLocaleDateString('id-ID', {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })
    },
    formatDateShort(date) {
      if (!date) {
        return;
      }

      if (date instanceof Date) {
        return date.toLocaleDateString('en-US', {
          weekday: 'short',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })
      }

      if ((date.toString() || '').indexOf('/') !== -1) {
        // unslashed date
        let usd = date.split('/');
        
        let formatted = new Date(usd[2], parseInt(usd[0]) - 1, usd[1]);

        return formatted.toLocaleDateString('en-US', {
          weekday: 'short',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })
      }

      const isParsed = (date || '').indexOf('T') !== -1;

      const d = !isParsed ? date.replace(/ /g, 'T') : date;

      return new Date(d).toLocaleDateString('en-US', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })
    },

    cancelEditForm() {
      this.editFormId = ''
      this.apiGet()
    },
  },
}
</script>